import { Options, Vue }                    from "vue-class-component";
import { FilterMatchMode, FilterOperator } from "primevue/api";

import { ZonesRoutesEnum }                 from "../../router";

import { zonesService }                    from "@services/zones.service";
import { SessionStorageEnum }              from "@/utils/SessionStorageEnum";

import {  
  AppTable,
} from "@components";

import { Zone } from "@/model/api/Zone";
import { ZoneTypeEnum } from "@/model/enums/ZoneTypeEnum";

@Options({
  components: {
    AppTable,
  },

  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith(ZonesRoutesEnum.DUMP_PREFIX)) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_DUMPS);
    }

    next();
  },
  
  beforeRouteLeave() {
    if (this.service?.cancelPendingRequests) {
      this.service.cancelPendingRequests();
    }
  },
})
export default class ZonesPage extends Vue {
  filters: any = null;

  get service() {
    return zonesService;
  }

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_DUMPS
  }

  get newZoneRoute(){
    return {
      name: ZonesRoutesEnum.DUMP_DETAIL,
      params: { zoneId: 'new' }
    }
  }

  goToDetail(zone: Zone) {
    this.$router.push({
      name: ZonesRoutesEnum.DUMP_DETAIL,
      params: { zoneId: zone.id }
    })
  }

  async onDelete(zone: Zone) {
    const response = await this.$confirmMessage(
      this.$t('dumping_site.messages.delete_confirm')  
    );

    if (response) {
      this.deleteZone(zone);
    }
  }
  
  private deleteZone(zone: Zone) {
    this.$waitFor(
      async () => {
        await zonesService.deleteBySystem(zone);

        this.$successMessage(
          this.$t('dumping_site.messages.delete_success')   
        )
      }
    );
  }
  
  private initFilter() {
    this.filters = {
      global: { 
        value: null, 
        matchMode: FilterMatchMode.CONTAINS 
      },
      name: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS 
      },
      w3: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS 
      },
      zone_type: {
        value: ZoneTypeEnum.DUMP,
        matchMode: FilterMatchMode.EQUALS
      }
    };
  }
  
  created() {
    this.initFilter();
  }
}