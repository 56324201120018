import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_AppTable = _resolveComponent("AppTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('dumping_site.list.title')), 1),
      _createVNode(_component_router_link, { to: _ctx.newZoneRoute }, {
        default: _withCtx(() => [
          _createVNode(_component_Button, {
            icon: "pi pi-plus",
            label: "New"
          })
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createVNode(_component_AppTable, {
      filtersSchema: _ctx.filters,
      service: _ctx.service,
      stateKey: _ctx.stateKey,
      onRowClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToDetail($event.data))),
      onEdit: _ctx.goToDetail,
      onDelete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDelete($event.data)))
    }, {
      columns: _withCtx(({onApplyFilter}) => [
        _createVNode(_component_Column, {
          field: "name",
          header: _ctx.$t('dumping_site.table.name'),
          sortable: true,
          showFilterMatchModes: false
        }, {
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              placeholder: _ctx.$t('dumping_site.table.filters.by_name'),
              onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "onKeyup"])
          ]),
          filterclear: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              icon: "pi pi-times",
              class: "p-button-outlined",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              icon: "pi pi-check",
              label: "Applica",
              class: "p-button-success",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          _: 2
        }, 1032, ["header"]),
        _createVNode(_component_Column, {
          field: "w3",
          header: _ctx.$t('dumping_site.table.w3'),
          showFilterMatchModes: false
        }, {
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              placeholder: _ctx.$t('dumping_site.table.filters.by_w3'),
              onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "onKeyup"])
          ]),
          filterclear: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              icon: "pi pi-times",
              class: "p-button-outlined",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              icon: "pi pi-check",
              label: "Applica",
              class: "p-button-success",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          _: 2
        }, 1032, ["header"]),
        _createVNode(_component_Column, {
          field: "info_zone",
          header: _ctx.$t('dumping_site.table.info')
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "note_public",
          header: _ctx.$t('dumping_site.table.public_notes')
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "contact_person",
          header: _ctx.$t('dumping_site.table.contact_person'),
          style: {"min-width":"10rem"}
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "contact_phone",
          header: _ctx.$t('dumping_site.table.contact_phone'),
          style: {"min-width":"10rem"}
        }, null, 8, ["header"])
      ]),
      _: 1
    }, 8, ["filtersSchema", "service", "stateKey", "onEdit"])
  ]))
}