import { markRaw } from "vue";

import * as w3api from "@what3words/api";

import { toRaw }           from "@vue/reactivity";
import { Options, Vue }    from "vue-class-component";
import { Prop }            from "vue-property-decorator";
import { ZonesRoutesEnum } from "../../router";

import {
  StateAutocomplete,
  W3GetCoordinates
} from "@components";

import { Zone }         from "@/model/api/Zone";
import { State }        from "@/model/api/State";
import { ZoneTypeEnum } from "@/model/enums/ZoneTypeEnum";
import { ZoneFile }     from "@/model/api/ZoneFile";

import { zonesService } from "@services/zones.service";


@Options({
  components: {
    StateAutocomplete,
    W3GetCoordinates
  },

  setup() {
    const shape: google.maps.Polygon = markRaw(null);

    return {
      shape
    }
  }
})
export default class DumpPage extends Vue {
  @Prop() readonly zoneId!: string;

  selectedState: string | State           = null;
  w3Result     : w3api.LocationJsonResponse = null;
  
  zone: Zone = new Zone();

  mapRef: google.maps.Map     = null;
  marker: google.maps.Marker  = null;

  get isNew(){
    return this.zoneId === 'new';
  }

  get map() {
    return toRaw(this.mapRef);
  }

  get backRoute() {
    return { name: ZonesRoutesEnum.DUMP_LIST }
  }

  get mapElement() {
    return (this.$refs.mapEl as HTMLElement)
  }

  get w3El() {
    return this.$refs.w3El as typeof W3GetCoordinates;
  }
  
  get files(): ZoneFile[] {
    return this.zone?.files;
  }

  uploadInProgress: boolean = false; 

  selectedFile : File = null;

  get fileName() {
    return this.selectedFile?.name;
  }

  onAddPhoto() {
    (this.$refs.file_input as HTMLInputElement).click();
  }

  onUpload() {
    this.uploadFile();
  }

  onRemoveFile(fileId: number) {
    this.removeFile(fileId);
  }

  onFilesSelect(event: Event) {
    const target = event.target as HTMLInputElement; 
    this.selectedFile = target.files.length && target.files[0];
  }


  onSelectedState(state: State) {
    this.zone.state_id = state.id;
    this.zone.state    = state;
  }

  onW3Result(result: w3api.LocationJsonResponse) {
    this.removeFirstClickListener();
    this.w3Result = result;

    this._updateMarker();
    this._updateShape();
    this._centerOn(result.coordinates)

    this.zone.lat       = this.w3Result.coordinates.lat;
    this.zone.lng       = this.w3Result.coordinates.lng;
  }
  
  async  onResetMap() {
    this.zone.shape = null; 
    
    (this as any).shape.setMap(null); 

    if (this.zone.w3) {
      this.onW3Result(await w3api.convertToCoordinates(this.zone.w3))
    }
  }

  onSave() {
    this.zone.zone_type = ZoneTypeEnum.DUMP;

    const a = (this as any).shape.getPath().getArray();
    this.zone.shape = a.map(p => [p.lng(), p.lat()]);

    this.zone.closeShape(); 

    this.$waitFor(
      async () => {
        if (this.isNew) {
          await this.saveNewZone();
        } else {
          await this.updateZone();
        }
      }
    );
  }

  private async removeFile(fileId: number) {
    try {
      await zonesService.deleteFile(fileId);

      const idx = this.zone.files.findIndex(x => x.id === fileId);
      this.zone.files.splice(idx, 1);

    } catch (error) {
      this.$errorMessage("Deleting failed");
    }
  }
  
  private async uploadFile() {
    try {
      this.uploadInProgress = true;

      const response = await zonesService.uploadFiles(
        +this.zoneId,
        this.selectedFile
      );

      this.selectedFile = null;

      this.zone.files ??= [];
      this.zone.files.push(response);

    } catch (error) {
      console.error(error);
      this.$errorMessage("Upload failed");
    } finally {
      this.uploadInProgress = false;
    }
  }
  
  private async updateZone() {
    await zonesService.updatePatch(this.zone);

    this.$successMessage(
      this.$t("dumping_site.messages.update_success")
    )
  }

  private async saveNewZone() {
    const response = await zonesService.create(this.zone);

    await this.$router.replace({
      name   : ZonesRoutesEnum.DUMP_DETAIL,
      params : { zoneId: response.id }
    })
    
    if (this.selectedFile) {
      await this.uploadFile();
    }

    this.$successMessage(this.$t("dumping_site.messages.create_success"));

    this._loadZone();
  }

  async onDelete(){
    const response =  await this.$confirmMessage(
      "Are you sure to delete this Dump point?"
    );

    if (response) {
      this.deleteZone();
    }
  }

  private deleteZone(){
    this.$waitFor(
      async () => {
        await zonesService.deleteBySystem(this.zone);

        this.$router.replace({ name: ZonesRoutesEnum.DUMP_LIST })
          .then( () => 
            this.$successMessage(
              this.$t("dumping_site.messages.delete_success")
            )
          );
      }
    )
  }

  private _initMarker() { 
    this.marker = new google.maps.Marker({
      draggable: true
    });

    this.marker.addListener('click', () => {
      this.map.setZoom(20);
      this.map.setCenter(this.marker.getPosition());
    });

    this.marker.addListener('dragend', () => {
      const latLng = this.marker.getPosition();
      const lat = latLng.lat();
      const lng = latLng.lng();

      this.zone.lat = lat;
      this.zone.lng = lng;

      w3api.convertTo3wa({lat, lng}).then( r => {
        this.zone.w3 = r.words;
      })
    });
  }

  private _initShape() {
    (this as any).shape = new google.maps.Polygon({
      strokeColor   : "00ffb7",
      strokeOpacity : 0.8,
      strokeWeight  : 2,
      fillColor     : "00ffb7",
      fillOpacity   : 0.35,
      editable      : true,
      draggable     : true
    })
  }

  private getPolygonPoints(sw: w3api.Coordinates, ne: w3api.Coordinates) {
    const km1 =  0.008; 

    const {
      lng: swLng,
      lat: swLat
    } = sw;
    sw.lat -= km1;
    sw.lng -= km1;

    const {
      lng: neLng,
      lat: neLat
    } = ne;
    ne.lat += km1;
    ne.lng += km1;

    const nw = {
      lat: neLat,
      lng: swLng
    }
    nw.lat += km1;
    nw.lng -= km1;

    const se = {
      lat: swLat,
      lng: neLng
    }
    se.lat -= km1;
    se.lng += km1;

    return [sw, se, ne, nw];

  }

  private _centerOn(coordinates) {
    this.map.setZoom(20);
    this.map.setCenter(coordinates);

    const s = ((this as any).shape as google.maps.Polygon);
    const path = s.getPath();
    
    const bounds = new google.maps.LatLngBounds();
    
    path.forEach((x) => {
      bounds.extend(x)
    });

    this.map.fitBounds(bounds);
  }

  
  private _updateMarker() {
    this.marker.setPosition(this.w3Result.coordinates);
    
    if (!this.marker.getMap()) {
      this.marker.setMap(this.map);
    }
  }

  private _updateShape() {
    if (!(this as any).shape.getMap()) {
      (this as any).shape.setMap(this.map);
    }

    let path = this.zone.shape?.map( x => 
      new google.maps.LatLng({lat:x[1], lng: x[0]}) 
    );

    if (!path) {
      const points = this.getPolygonPoints(
        this.w3Result.square.southwest,
        this.w3Result.square.northeast
      );

      path = points.map(x => new google.maps.LatLng(x))
    } 

    (this as any).shape.setPath(path);

    const bounds = new google.maps.LatLngBounds();
    
    path.forEach((x) => {
      bounds.extend(x)
    });

    this.map.fitBounds(bounds);
  }

  private _buildMap(element: HTMLElement, bounds?: google.maps.LatLngBounds) {

    this.mapRef = new google.maps.Map(
      element,
      {
        center: this.$config.startCenter,
        zoom: this.$config.startZoom,
      },
    );

    if (bounds) {
      this.map.fitBounds(bounds, 0);
    }

    this.map.data.setStyle({
      strokeWeight  : 1,
      strokeOpacity : 1,
      strokeColor   : "#3399FF",
      fillColor     : "#3399FF",
      fillOpacity   : 0.2,
      editable      : true,
      draggable     : true,
      clickable     : true,
      zIndex        : 1,
    });
  }
  
  private firstClickListener = null; 

  private removeFirstClickListener() {
    google.maps.event.removeListener(this.firstClickListener);
  }

  private _attachListeners() {
    const clickListener = async (e: google.maps.MapMouseEvent) => {

      const [lat, lng] = [e.latLng.lat(), e.latLng.lng()];

      this.w3Result = await w3api.convertTo3wa({ lat, lng, }, 'en');
      this.zone.w3  = this.w3Result.words;

      this.zone.lat = this.w3Result.coordinates.lat;
      this.zone.lng = this.w3Result.coordinates.lng;

      this._updateMarker();
      this._updateShape();
      this._centerOn(this.w3Result.coordinates);

      google.maps.event.removeListener(this.firstClickListener);
    };

    this.firstClickListener = this.map.addListener('click', clickListener);
  }

  private _showOnMap(zone: Zone) {
    const coordinates = new google.maps.LatLng({
      lat: zone.lat,
      lng: zone.lng   
    })

    this._centerOn(coordinates)

    this.marker.setPosition(coordinates);

    const path = this.zone.shape.map( x => 
      new google.maps.LatLng({lat:x[1], lng: x[0]}) 
    );

    (this as any).shape.setPath(path);

    (this as any).shape.setMap(this.map);
    this.marker.setMap(this.map);
    
    const bounds = new google.maps.LatLngBounds();
    
    path.forEach((x) => {
      bounds.extend(x)
    });

    this.map.fitBounds(bounds);
  }

  private _loadZone() {
    return this.$waitFor(
      async () => {
        this.zone = await zonesService.getById(+this.zoneId);
      },
      "Dumping site not found"
    )
  }

  async created() {
    if (this.isNew) {
      this.zone = new Zone();
    } else {
      await this._loadZone();

      this._showOnMap(this.zone);

      this.selectedState = this.zone.state;
    }
  }

  mounted() {
    this._initMarker();
    this._initShape();
    this._buildMap(this.mapElement);
    this._attachListeners();
  }
}